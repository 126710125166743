<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-card flat>
          <v-card-title>
            Lista de Proveedores

            <vue-excel-xlsx
              class="ml-5"
              :data="itemsRSeniorS"
              :columns="columns"
              :filename="'Reporte por Junior'"
              :sheetname="'sheetname'"
              style="background: #c1c1c1; padding: 2px 10px; border-radius: 5px"
            >
              Descargar
            </vue-excel-xlsx>

            <v-autocomplete
              :items="itemsCampana"
              item-text="name"
              item-value="id"
              class="ml-5 mt-5"
              label="Seleccionar Campaña"
              v-model="id_campana"
              @change="loadRSeniorS()"
            >
            </v-autocomplete>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchS"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-row>
            <v-col cols="12" md="4"> </v-col>
          </v-row>
          <h1>GANANCIA: $ {{ cal_ganancia }}</h1>
          <v-row>
            <v-col>
              <h4>Pago a Proveedores</h4>
              <v-data-table
                :headers="headersS"
                :items="itemsRSeniorS"
                :search="searchS"
                @click:row="handleClicks"
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon class="mr-2 mx-auto" @click="loadRSeniorSS(item.id)">
                    mdi-eye
                  </v-icon>
                  <v-icon
                    class="mr-2 mx-auto"
                    color="error"
                    @click="deleteItem(item.id)"
                  >
                    mdi-delete
                  </v-icon>

                  <v-icon
                    class="mr-2 mx-auto"
                    color="success"
                    v-if="item.status == 1"
                    @click="lockitem(item.id)"
                  >
                    mdi-lock-open
                  </v-icon>
                  <v-icon
                    class="mr-2 mx-auto"
                    v-if="item.status == 2"
                    color="error"
                    @click="unlockitem(item.id)"
                  >
                    mdi-lock
                  </v-icon>
                </template>
                <template slot="body.append">
                  <tr class="pink--text">
                    <th colspan="1" class="title">Totales</th>

                    <th colspan="1" class="title">
                      $ {{ sumFieldSS("compra_pro") }}
                    </th>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
            <v-col>
              <h3
                style="text-align: center; background: #d2d2d2; border-radius: 5"
              >
                Total Venta
              </h3>
              <b>Total Junior: $ {{ sumFieldSP("subtotal") }}</b> <br />
              <b>Total Senior: $ {{ sumFieldSP("subtotal_senior") }}</b>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <h4>Costos Logísticos</h4>
        <v-simple-table fixed-header height="500px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Nombre
                </th>
                <th class="text-left">
                  Monto
                </th>
                <th class="text-left">
                  IGV
                </th>
                <th class="text-left">
                  Total
                </th>
                <th class="text-left">
                  Acciones
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in itemsCostos" :key="item.name">
                <td>{{ item.name }}</td>
                <td>{{ item.monto }}</td>
                <td>{{ item.igv }}</td>
                <td>{{ item.total }}</td>
                <td>
                  <v-btn
                    @click="
                      viewCostos(
                        item.id,
                        item.name,
                        item.monto,
                        item.igv,
                        item.total
                      )
                    "
                    fab
                    x-small
                    elevation="0"
                  >
                    <v-icon color="grey">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr class="pink--text">
                <th colspan="1" class="title">Totales</th>

                <th colspan="1" class="title">$ {{ sumFieldCo("monto") }}</th>
                <th colspan="1" class="title">$ {{ sumFieldCo("igv") }}</th>
                <th colspan="1" class="title">$ {{ sumFieldCo("total") }}</th>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="12" md="6">
        <h4>Base Imponible: $ {{ gl_base }}</h4>
        <h4>IGV Base Imponible: $ {{ gl_igv_base }}</h4>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogrs" width="1000">
      <v-card>
        <v-card-title>
          Lista de {{ senior }}
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchSS"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headersSS"
          :items="itemsRSeniorSS"
          :search="searchSS"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon class="mr-2 mx-auto" @click="viewOrden(item.id)">
              mdi-eye
            </v-icon>
          </template>

          <template slot="body.append">
            <tr class="pink--text">
              <th colspan="5" class="title">Totales</th>
              <th class="title">{{ sumFieldS("cantidad") }}</th>
              <th class="title">{{ sumFieldS("precio") }}</th>
              <th class="title">{{ sumFieldS("subtotal") }}</th>
            </tr>
          </template>
        </v-data-table>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogrs = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogcostos" width="1000">
      <v-card>
        <v-card-title>
          COSTO DE {{ co_name }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                type="text"
                v-model="co_name"
                label="Nombre"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                type="number"
                v-model="co_monto"
                label="Monto"
                @keyup="calco"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                type="number"
                v-model="co_igv"
                label="IGV"
                @keyup="calco2"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                type="number"
                v-model="co_total"
                label="Total"
                disabled
                @keyup="calco"
              ></v-text-field>
            </v-col>
            <v-col></v-col>
            <v-col></v-col>
          </v-row>

          <v-btn block color="primary" @click="addCostos()" rounded
            >Guardar</v-btn
          >
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogcostos = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "RSenior",
  data: () => ({
    search: "",
    searchS: "",
    searchSS: "",
    senior: "",
    itemsRSenior: [],
    itemsRSeniorS: [],
    itemsRSeniorSP: [],
    itemsRSeniorSS: [],
    itemsCampana: [],
    itemsCostos: [],
    id: "",
    id_campana: "",
    name: "",
    dialogrs: "",
    description: "",
    status: true,
    statusData: 1,
    dialogcostos: false,
    gl_base: "",
    cal_ganancia: "",
    gl_igv_base: "",

    co_name: "",
    co_monto: "",
    co_igv: "",
    co_total: "",
    co_id: "",

    headers: [
      {
        text: "Junior",
        align: "start",

        value: "junior",
      },
      { text: "Catálogo", value: "catalogo" },
      { text: "Código", value: "codigo_producto" },
      { text: "Página", value: "pagina" },
      { text: "Producto", value: "producto" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Precio", value: "precio" },
      { text: "Sub Total", value: "subtotal" },
    ],

    headersS: [
      {
        text: "Proveedor",
        align: "start",
        value: "proveedor",
      },

      { text: "Sub Total Compra", value: "compra_pro" },
    ],

    columns: [
      {
        label: "Proveedor",
        align: "start",
        field: "proveedor",
      },
      { label: "SubTotal", field: "subtotal_prov" },
      { label: "Sub Total Compra", field: "compra_pro" },
      { text: "Ganancia", value: "ganancia" },
    ],

    headersSS: [
      {
        text: "Junior",
        align: "start",
        value: "junior",
      },
      { text: "Catálogo", value: "catalogo" },
      { text: "Código", value: "codigo_producto" },
      { text: "Página", value: "pagina" },
      { text: "Producto", value: "producto" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Precio", value: "precio" },
      { text: "Sub Total", value: "subtotal" },
    ],
  }),
  mounted() {
    this.loadCampanas();
    this.loadRSenior();
    //this.loadRSeniorS();
  },
  methods: {
    loadCampanas() {
      axios.get(process.env.VUE_APP_API_URL + "campana_all").then((result) => {
        this.itemsCampana = result.data;
      });
    },
    calco() {
      var monto = parseFloat(this.co_monto);
      var igv = parseFloat(this.co_igv);
      var total = parseFloat(this.co_total);

      igv = monto * 0.18;
      total = monto + igv;

      this.co_igv = igv.toFixed(2);
      this.co_total = total.toFixed(2);
    },
    calco2() {
      this.co_total = this.co_monto;
    },
    loadRSeniorSP() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "rep/campana/senior_with/" +
            this.id_campana
        )
        .then((result) => {
          this.itemsRSeniorSP = result.data;
          this.costoGlobal();
        });
    },
    loadCostos() {
      axios
        .get(process.env.VUE_APP_API_URL + "campana/costos/" + this.id_campana)
        .then((result) => {
          this.itemsCostos = result.data;
          this.loadRSeniorSP();
        });
    },
    loadRSenior() {
      axios.get(process.env.VUE_APP_API_URL + "rep/senior").then((result) => {
        this.itemsRSenior = result.data;
      });
    },
    handleClick(row) {
      this.loadRSeniorSS(row.id);
    },

    viewCostos(id, name, monto, igv, total) {
      this.co_id = id;
      this.co_name = name;
      this.co_monto = monto;
      this.co_igv = igv;
      this.co_total = total;
      this.dialogcostos = true;
    },

    loadRSeniorS() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "rep/proveedor/campana/" +
            this.id_campana
        )
        .then((result) => {
          this.itemsRSeniorS = result.data;

          this.loadCostos();
        });
    },
    loadRSeniorSS(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "rep/junior/" + id)
        .then((result) => {
          this.dialogrs = true;
          this.senior = result.data[0].senior;
          this.itemsRSeniorSS = result.data;
        });
    },
    editItem(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "RSenior/" + id)
        .then((result) => {
          this.id = result.data[0].id;
          this.name = result.data[0].name;
          this.description = result.data[0].description;
          this.status = result.data[0].status;
          this.statusData = 2;
        });
    },
    addData() {
      let data = {
        name: this.name,
        description: this.description,
        status: this.status,
      };
      axios
        .post(process.env.VUE_APP_API_URL + "RSenior", data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Rubro registrado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.loadRSenior();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    updateData() {
      let data = {
        name: this.name,
        description: this.description,
        status: this.status,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "RSenior/" + this.id, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Rubro actualizado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.cleanData();
            this.loadRSenior();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },

    addCostos() {
      let data = {
        name: this.co_name,
        monto: this.co_monto,
        igv: this.co_igv,
        total: this.co_total,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "costos/monto/" + this.co_id, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Monto actualizado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.cleanCosto();
            this.loadCostos(this.id_campana);
            this.dialogcostos = false;
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },

    cleanCosto() {
      this.co_monto = "";
      this.co_igv = "";
      this.co_total = "";
    },

    costoGlobal() {
      this.gl_base = (this.sumFieldSS("subtotal_prov") / 1.118).toFixed(2);
      this.gl_igv_base = (
        this.sumFieldSS("subtotal_prov") - this.gl_base
      ).toFixed(2);

      var a = parseFloat(this.sumFieldSP("subtotal_senior"));
      var b = parseFloat(this.sumFieldSS("compra_pro"));
      var c = parseFloat(this.sumFieldCo("total"));
      this.cal_ganancia = (a - (b + c)).toFixed(2);
    },

    lockitem(id) {
      let data = {
        status: 2,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "productos/lock/junior/" + id, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            this.loadRSeniorS();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    unlockitem(id) {
      let data = {
        status: 1,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "productos/lock/junior/" + id, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            this.loadRSeniorS();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    sumField(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsRSenior.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    sumFieldS(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsRSeniorSS.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    sumFieldCo(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsCostos.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    sumFieldSS(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsRSeniorS.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },
    sumFieldSP(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsRSeniorSP.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },
    deleteItem(id) {
      Swal.fire({
        title: "¿Está seguro de Eliminar?",
        text: "Una vez eliminada, la orden no se podrá recuperar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar",
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            title: "¿Está completamente seguro de Eliminar?",
            text: "Una vez eliminada, la orden NO se podrá recuperar",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "No, cancelar",
          }).then((result) => {
            if (result.value) {
              axios
                .delete(process.env.VUE_APP_API_URL + "productos/junior/" + id)
                .then((result) => {
                  console.log(result.data);
                  if (result.data.boolean == true) {
                    Swal.fire({
                      position: "center",
                      icon: "success",
                      title: "Orden eliminada con éxito",
                      showConfirmButton: true,
                      timer: 5000,
                    });
                    this.loadRSeniorS();
                  } else {
                    Swal.fire({
                      position: "center",
                      icon: "error",
                      title: "Oops...",
                      text: result.data.error.sqlMessage,
                      showConfirmButton: true,
                      timer: 8000,
                    });
                  }
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire("Cancelado", "La orden no fue eliminado", "error");
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelado", "La orden no fue eliminado", "error");
        }
      });
    },
    cleanData() {
      this.name = "";
      this.description = "";
      this.status = true;
      this.statusData = 1;
    },
  },
};
</script>
